import React, { useState } from 'react';
import Lottie from 'react-lottie';
import animationData from './FishAnimation1.json'
import textToSpeech from './TextToSpeech';
import noWarInDecades from './static_audio/no-war-in-decades.mp3';
import './App.css';
import { Buffer } from 'buffer';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


// @ts-ignore
window.Buffer = Buffer;

const App = () => {
  const [inputText, setInputText] = useState('');
  const [animationState, setAnimationState] = useState({
    isStopped: true,
    isPaused: false,
  });
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const location = useLocation();
  const [isSharedLink, setIsSharedLink] = useState(false);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const encodedText = queryParams.get('say');
    if (encodedText) {
      const decodedText = decodeURIComponent(atob(encodedText)); // Decode the Base64 text
      setInputText(decodedText);
      setIsSharedLink(true);
    }
  }, [location]);

  const createShareableLink = () => {
    const baseUrl = window.location.origin;
    const encodedText = btoa(encodeURIComponent(inputText)); // Base64 encode the text
    const shareableUrl = `${baseUrl}?say=${encodedText}`;
    navigator.clipboard.writeText(shareableUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  };



  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const playPredefinedQuote = (audioFile) => {
    const audio = new Audio(audioFile);
    setAnimationState({ isStopped: false, isPaused: false });
    audio.play();
    audio.onended = () => {
      setAnimationState({ isStopped: true, isPaused: false });
    };
  };

  const handleSpeak = async () => {
    if (inputText.trim()) {
      setIsLoading(true);
      const response = await textToSpeech(inputText);
      setIsLoading(false);
  
      if (response && response.audioContent) {
        setAnimationState({ isStopped: false, isPaused: false });
        const audioBlob = new Blob([new Uint8Array(Buffer.from(response.audioContent, 'base64'))], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
  
        // Directly attempt to play the audio
        audio.play().then(() => {
          console.log('Playback started');
        }).catch(error => {
          console.error('Playback failed', error);
        });
  
        audio.onended = () => {
          setAnimationState({ isStopped: true, isPaused: false });
          URL.revokeObjectURL(audioUrl);
        };
      } else {
        console.error('No audio content received', response);
      }
    }
  };
  
  
  const playSharedMessage = async () => {
    setIsLoading(true);
    const response = await textToSpeech(inputText);
    setIsLoading(false);
    if (response && response.audioContent) {
      setAnimationState({ isStopped: false, isPaused: false });
      const audioBlob = new Blob([new Uint8Array(Buffer.from(response.audioContent, 'base64'))], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
  
      audio.onloadeddata = () => {
        audio.play();
      };
  
      audio.onended = () => {
        setAnimationState({ isStopped: true, isPaused: false });
        URL.revokeObjectURL(audioUrl);
      };
    } else {
      console.error('No audio content received', response);
    }
  };
  
  const playAudio = (audioContent) => {
    setAnimationState({ isStopped: false, isPaused: false });
    const audioBlob = new Blob([new Uint8Array(Buffer.from(audioContent, 'base64'))], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);
  
    audio.onloadeddata = () => {
      audio.play();
    };
  
    audio.onended = () => {
      setAnimationState({ isStopped: true, isPaused: false });
      URL.revokeObjectURL(audioUrl);
    };
  };
  
  
  

  const handleReset = () => {
    setIsSharedLink(false); // Reset the shared link flag
    setInputText(''); // Clear the input text
    setAnimationState({ isStopped: true, isPaused: false }); // Reset animation state
  };
  

  return (
    <div style={{ backgroundColor: 'red', color: 'white', fontFamily: 'Montserrat', height: 'auto', margin: 0, padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
      <div className='mobile-only'>
        <h1 style={{ fontFamily: 'Times New Roman', fontSize: '3.5em', color: 'white', margin: '0', textAlign: 'center' }}>
          <span className="material-icons" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
          <span className="material-icons" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
          <span className="material-icons" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
        </h1>
      </div>
      <h1 style={{ fontFamily: 'Times New Roman', fontSize: '3.5em', color: 'white', marginBottom: '0', textAlign: 'center' }} className='no-margin-on-mobile'>
        <span className="material-icons mobile-hide" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
        <span className="material-icons mobile-hide" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
        <span className="material-icons mobile-hide" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
        DONALD TROUT
        <span className="material-icons mobile-hide" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
        <span className="material-icons mobile-hide" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
        <span className="material-icons mobile-hide" style={{ color: 'navy', fontSize: '.8em' }}>star</span>
      </h1>

      <h2 style={{ fontFamily: 'Times New Roman', fontSize: '2em', color: 'white', marginTop: '0', marginBottom: '0', textAlign: 'center' }}>
        MAKE FISH GREAT AGAIN
      </h2>
      <div style={{ height: '3px', width: '80%', maxWidth: '420px', background: 'white', margin: '6px auto' }}></div>
      <div style={{ height: '3px', width: '80%', maxWidth: '420px', background: 'white', margin: '6px auto' }}></div>
      <div className="lottie-container" style={{ pointerEvents: 'none' }}>
        <Lottie options={defaultOptions} isStopped={animationState.isStopped} isPaused={animationState.isPaused} />
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : isSharedLink ? (
        <>
        <button onClick={() => handleSpeak(inputText)}style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: 'yellow', fontSize: '16px', border: 'none', cursor: 'pointer', fontFamily: 'Montserrat, sans-serif' }}>
          Play your shared message
        </button>
        

        <button onClick={handleReset} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: 'yellow', fontSize: '16px', border: 'none', cursor: 'pointer', fontFamily: 'Montserrat, sans-serif' }}>
          Create Your Own Message
        </button>
        </>
        
        
      ) : (
        <>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <textarea
                style={{ width: '80%', maxWidth: '600px', height: '60px', marginTop: '20px', fontSize: '16px', fontFamily: 'Montserrat, sans-serif', padding: "20px" }}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Type something for Donald Trout to say, but the more you input the more he needs to think about what he says."
              />
              <button
                onClick={handleSpeak}
                style={{ marginTop: '10px', padding: '10px 20px', backgroundColor: 'yellow', fontSize: '16px', border: 'none', cursor: 'pointer', fontFamily: 'Montserrat, sans-serif' }}
              >
                Speak to Me
              </button>
              <button onClick={createShareableLink} style={{ marginTop: '10px', padding: '10px 20px', backgroundColor: 'yellow', fontSize: '16px', border: 'none', cursor: 'pointer', fontFamily: 'Montserrat, sans-serif' }}
              >Share</button>
            </>
          )}
          
          {/*<div className='quotes-wrapper'>
            <h3>Quote of the day:</h3>
            <a href="#" onClick={(e) => { e.preventDefault(); playPredefinedQuote(noWarInDecades); }}>
              And yet I’ve gone decades, decades without a war. The first president to do it for that long a period.
            </a>
          </div>*/}
        </>
      )}
    </div>
  );



};

export default App;
