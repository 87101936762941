import axios from 'axios';

const textToSpeech = async (text) => {
  const backendUrl = '/.netlify/functions/synthesize';  // Netlify Function endpoint
  const payload = {
    data: {
      text: text,
      voice_engine: 'PlayHT2.0',
      voice: 's3://voice-cloning-zero-shot/c8644087-f234-4a44-9871-c3ddbe769eac/original/manifest.json',
      output_format: 'mp3',
      sample_rate: '44100',
      speed: .8,
    },
    apiKey: '8911919a8c914bd4b66273970f7346a1',
    userId: 'wFdmRgd5LoVAPyPIlrTt3kgqqGr2',
  };

  try {
    const response = await axios.post(backendUrl, payload);
    console.log(text);
    console.log(payload);
    return response.data;  // Adjust based on how your server sends the audio file or URL
  } catch (error) {
    console.error('Error in text-to-speech conversion:', error);
    return null;
  }
};

export default textToSpeech;
